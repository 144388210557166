import React, { useEffect } from 'react';
import { StyleSheet, View, ScrollView, TouchableOpacity, Container, Ratio, Text, Constants, ManageCard, Assets, Functions, Models, Colors, Image, NavButton } from 'utils/imports.utils';
import { changeFilter} from 'utils/redux.utils';
import { useSelector } from 'react-redux';

const Manage = (props: any) => {
  const [state, setState] = Functions.useSetState({ loading: true });
  const user = useSelector((store: any) => store.user.user);

  useEffect(() => {
      getDashboardDetails();

  }, [user]);

  const getDashboardDetails = async () => {
    try {
      let req:any = {}
      if(user.role === 'trainer'){
        req.trainer = user._id
      }
      const data: any = await Models.dashboard.manageCardDetails(req);
      setState({ ...data.data, loading: false });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure("Failed to get pet list");
      }
      setState({ loading: false });
    }
  }

  return (
    <Container styles={styles.manageScreen} screen loading={state.loading} >
      <ScrollView
        showsVerticalScrollIndicator={false}
      >
        {/* <Text color={Colors.primaryDarkColor} size={Ratio(24)} family={Constants.regular}>Manage</Text> */}
        <View style={styles.managContainer}>
          <View style={styles.manageHeader}>
            <Text size={Ratio(24)} color={Colors.primaryDarkColor} family={Constants.regular}>Manage</Text>
            {/* <View style={styles.notificationProfile}>
            <View style={styles.headerWrapper}>
          <NavButton
            onClick={() => {}}
            icon={Assets.Notification}
            svg
            badge={state.notify !== 0 ? true : false}
            badgeCount={state.notify}
          />
        </View>
            {user.profile ? (
              <TouchableOpacity
                activeOpacity={0.6}
                onPress={() => props.navigation.navigate('Settings')}>
                <Image
                  src={user.profile}
                  url
                  width={Ratio(55)}
                  height={Ratio(55)}
                  resize={'cover'}
                  radius={Ratio(20)}
                />
              </TouchableOpacity>
            ) : (
              <View>
                <NavButton
                  icon={Assets.SingleUserPrimary}
                  svg
                  onClick={() => props.navigation.navigate('Settings')}
                  iconHeight={Ratio(35)}
                  iconWidth={Ratio(35)}
                />
              </View>
            )}
              </View> */}
          </View>
          
          <View style={styles.manageContent}>
            {
              user.role === "super_admin" &&
              <>
              <View style={styles.manageCard}>
                <TouchableOpacity activeOpacity={0.7} onPress={() => props.navigation.navigate("Organization")}>
                  <ManageCard icon={Assets.organization} iconHeight={Ratio(30)} iconWidth={Ratio(50)} title="Organization" description={`${state?.org_count ? state?.org_count : 0} Organizations`} />
                </TouchableOpacity>
              </View>
              <View style={styles.manageCard}>
                <TouchableOpacity activeOpacity={0.7} onPress={() => props.navigation.navigate("AdminList", { type: "org_admin", title: "Org Admin" })}>
                  <ManageCard icon={Assets.admin} iconHeight={Ratio(30)} iconWidth={Ratio(50)} title="Org Admin" description={``} />
                </TouchableOpacity>
              </View>
              </>
            }
            {
              (user.role === "org_admin") &&
              <>
              <View style={styles.manageCard}>
                <TouchableOpacity activeOpacity={0.7} onPress={() => props.navigation.navigate("AdminList", { type: "org_admin", title: "Org Admin" })}>
                  <ManageCard icon={Assets.admin} iconHeight={Ratio(30)} iconWidth={Ratio(50)} title="Org Admin" description={``} />
                </TouchableOpacity>
              </View>
              <View style={styles.manageCard}>
                <TouchableOpacity activeOpacity={0.7} onPress={() => props.navigation.navigate("Branch")}>
                  <ManageCard icon={Assets.branch} iconHeight={Ratio(30)} iconWidth={Ratio(50)} title="Branch" description={`${state?.branch_count ? state?.branch_count : 0} Branch`} />
                </TouchableOpacity>
              </View>
              </>
            }

            {
              (user.role === "org_admin" || user.role === "admin") &&
              <>
                <View style={styles.manageCard}>
                  <TouchableOpacity activeOpacity={0.7} onPress={() => props.navigation.navigate("AdminList", { type: "admin", title: "Branch Admin" })}>
                    <ManageCard icon={Assets.admin} iconHeight={Ratio(30)} iconWidth={Ratio(50)} title="Branch Admin" description={``} />
                  </TouchableOpacity>
                </View>
              </>
            }
            {
              user.role === "admin" &&
              <View style={styles.manageCard}>
                <TouchableOpacity activeOpacity={0.7} onPress={() => props.navigation.navigate("Clients", { type: "trainer", title: "Trainers" })}>
                  <ManageCard icon={Assets.WhistlePrimary} iconHeight={Ratio(50)} iconWidth={Ratio(50)} title="Trainers" description={`${state?.trainers_count ? state?.trainers_count : 0} Trainers`} />
                </TouchableOpacity>
              </View>
            }
            {
              (user.role === "admin" || user.user_type === "trainer") &&
              <>
                <View style={styles.manageCard}>
                  <TouchableOpacity activeOpacity={0.7} onPress={() => props.navigation.navigate("Clients", { type: "customer", title: "Clients" })}>
                    <ManageCard icon={Assets.GroupUserPrimary} iconHeight={Ratio(50)} iconWidth={Ratio(50)} title="Clients" description={`${state?.clients_count ? state?.clients_count : 0} Clients`} />
                  </TouchableOpacity>
                </View>
                <View style={styles.manageCard}>
                  <TouchableOpacity activeOpacity={0.7} onPress={() => props.navigation.navigate("DogList")}>
                    <ManageCard icon={Assets.DogPrimary} iconHeight={Ratio(50)} iconWidth={Ratio(50)} title="Dogs" description={`${state?.pets_count ? state?.pets_count : 0} Dogs`} />
                  </TouchableOpacity>
                </View>
                <View style={styles.manageCard}>
                  <TouchableOpacity activeOpacity={0.7} onPress={() => props.navigation.navigate("Trainings" , {from: "manage"})}>
                    <ManageCard icon={Assets.TrainingPrimary} iconHeight={Ratio(50)} iconWidth={Ratio(50)} title="Training" description={`${state?.trainings_count ? state?.trainings_count : 0} Trainings`} />
                  </TouchableOpacity>
                </View>
              </>
            }
            {user.role=== "admin" && <View style={styles.manageCard}>
                  <TouchableOpacity activeOpacity={0.7} onPress={() => props.navigation.navigate("Services")}>
                    <ManageCard icon={Assets.services} iconHeight={Ratio(50)} iconWidth={Ratio(50)} title="Services" description={`${state?.services_count ? state?.services_count : 0} Services`} />
                  </TouchableOpacity>
                </View>}
          </View>
        </View>
      </ScrollView>
    </Container>
  )
}

const styles = StyleSheet.create({
  manageScreen: {
    width: "100%",
    height: "100%",
    paddingBottom: 0,
  },
  managContainer: {
    width: "100%",
    height: "100%",
    padding: Ratio(20),
  },
  manageContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingTop: Ratio(15),
  },
  manageCard: {
    marginTop: "20px",
    width: "49%",
    cursor:"pointer",
  },
  manageHeader:{
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  notificationProfile:{

    gap:"20px",
    display:"flex",
    flexDirection: 'row',
    alignItems:"center",
    justifyContent: 'space-between',
  }
})

export default Manage;