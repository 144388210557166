import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Text,
  Container,
  Assets,
  Functions,
  NavButton,
  Colors,
  Constants,
  Ratio,
  Input,
  Models,
  PetCard,
  FilterModal,
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  LinearGradient
} from 'utils/imports.utils';
import { getTrainingDetails, getTrainingList } from 'utils/redux.utils';
import _ from 'lodash';
interface paginationProps {
  page: number;
  limit: number;
  search: string;
  filter?: string;
}

const Trainings = (props: any) => {
  const trainings = useSelector((state: any) => state.training.trainingList);
  const filter = useSelector((state: any) => state.training.filter);
  const user = useSelector((state: any) => state.user.user);

  const [state, setState] = Functions.useSetState({
    searchVisible: false,
    page: 1,
    limit: 20,
    search: '',
    loading: true,
    filter: filter,
  });

  useEffect(() => {
    getDashboardDetails();
  }, []);

  const getDashboardDetails = async () => {
    try {
      let req: any = {};
      if (user.user_type === 'trainer') {
        req.trainer = user._id;
      }
      const data: any = await Models.dashboard.manageCardDetails(req);
      setState({ ...data.data });
      getTrainings({
        page: state.page,
        limit: state.limit,
        search: state.search,
        filter: state.filter
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get pet list');
      }
      setState({ loading: false });
    }
  };

  const backAction = () => {
    getTrainingList([]);
    // props.navigation.goBack();
    props.navigation.navigate('Manage');
    return true;
  };

  const getTrainings = async ({ page, limit, search, filter }: paginationProps) => {
    try {
      setState({ loadMoreLoader: true });
      let req: any = { page, limit, search };
      if (filter) {
        req.status = filter;
      }
      if (user.user_type === 'trainer') {
        req.trainer = user._id;
      }
      const training: any = await Models.training.trainingList(req);
      if (page !== 1) {
        getTrainingList([...trainings, ...training.data.docs]);
      } else {
        getTrainingList(training.data.docs);
      }
      setState({
        loading: false,
        hasMore: training.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
        filter,
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get pet list');
      }
      setState({ loading: false });
    }
  };

  const goBack = () => {
    if (props.route.params.from == 'add_training') {
      props.navigation.navigate('Manage');
    } else props.navigation.goBack();
  };

  const getSearch = () => {
    setState({ searchVisible: !state.searchVisible });
    getTrainings({ limit: state.limit, page: 1, search: '', filter: state.filter });
  };

  const loadMore = () => {
    if (state.hasMore) {
      getTrainings({ limit: state.limit, page: state.page + 1, search: state.search });
    }
  };

  const closeAction = () => {
    setState({ openFilter: !state.openFilter, filter: '' });
  };

  const closeSearch = () => {
    setState({ searchVisible: !state.searchVisible, search: '' });
    console.log('search,', state.filter);
    getTrainings({ limit: state.limit, page: 1, search: '', filter: state.filter });
  };

  const gotoAddTraining = () => {
    props.navigation.navigate('AddTraining', { add: true });
  };

  const trainingDetails = (item: any) => {
    if (props.route.params.from === 'home') {
      getTrainingDetails({ ...item, ...{ adminView: true } });
      props.history.push('DogProfile', { id: item.pet._id });
    } else {
      props.navigation.navigate('AddTraining', { view: true, id: item._id });
    }
  };

  const filterChange = (value: string) => {
    setState({ openFilter: !state.openFilter });
    getTrainings({ limit: state.limit, page: 1, search: '', filter: value });
  };

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };

  const returnDate = (item: any, type?: any) => {
    if (item.training_type == 'range') {
      if (type == 'start_date') {
        return item.start_date;
      } else {
        return item.end_date;
      }
    } else if (item.training_type == 'multiple days') {
      if (type == 'start_date') {
        return item.dates[0];
      } else {
        return item.dates[item.dates.length - 1];
      }
    } else if (item.training_type == 'single day') {
      return item.date;
    } else {
      if (item.start_date && type == 'start_date') {
        return item.start_date;
      } else {
        return item.end_date;
      }
    }
  };

  return (
    <Container
      screen
      styles={styles.trainingsContainer}
      loading={state.loading}
      floatingButtonStyle={{ right: 150 }}
      // floatingButton={user.user_type === 'admin'}
      onFloationButtonClick={gotoAddTraining}>
      <View style={styles.trainingsWrapper}>
        <View style={styles.headerWrapper}>
          <View style={styles.headerContent}>
            {!state.searchVisible && !state.openFilter && (
              <View>
                <NavButton
                  icon={Assets.ArrowBack}
                  style={{ backgroundColor: Colors.background }}
                  svg
                  onClick={() => goBack()}
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
            )}
            {!state.searchVisible && !state.openFilter && (
              <View style={styles.headTextContainer}>
                <View style={{ height: '70%' }}>
                  <Text size={Ratio(24)} color={Colors.primaryDarkColor} family={Constants.regular}>
                    Trainings
                  </Text>
                </View>
                <View style={{ height: '30%' }}>
                  <Text size={Ratio(12)} color={Colors.secondaryDarkColor}>{`${
                    trainings.length
                  } Trainings`}</Text>
                </View>
              </View>
            )}
          </View>
          {!state.searchVisible && !state.openFilter ? (
            <View style={styles.headerContent}>
              <View style={styles.navButtonContainer}>
              <NavButton icon={Assets.Plus} style={styles.addButton} onClick={gotoAddTraining}/>
                <NavButton
                  icon={Assets.Search}
                  onClick={closeSearch}
                  svg
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
              <View style={styles.navButtonContainer}>
                <NavButton
                  icon={Assets.TuneBlue}
                  onClick={() => setState({ openFilter: !state.openFilter })}
                  svg
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
            </View>
          ) : (
            <View style={styles.headerContent}>
              {state.searchVisible ? (
                <View style={styles.navButtonContainerSearch}>
                <NavButton icon={Assets.Plus} style={styles.addButton} onClick={gotoAddTraining} height={40} width={45}/>

                  <View style={styles.searchContainer}>
                    <Input
                      name="search"
                      onChange={(search: any) => {
                        if (!search.target) {
                          setState({ search });
                          getTrainings({ limit: state.limit, page: 1, search: search });
                        }
                      }}
                      value={state.search}
                      iconPosition="start"
                      source={Assets.Search}
                      iconHeight={20}
                      iconWidth={20}
                      styles={styles.input}
                      placeholder="Search"
                    />
                  </View>
                  <NavButton
                    icon={Assets.Close}
                    onClick={getSearch}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                  />
                </View>
              ) : (
                <View style={styles.navButtonContainer}>
                  <NavButton
                    icon={Assets.Close}
                    onClick={closeAction}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                  />
                </View>
              )}
            </View>
          )}
        </View>
        {trainings.length > 0 ? (
          <View style={styles.dogListWrapper}>
            <FlatList
              data={trainings}
              scrollEnabled={true}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item: any) => item.name}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  activeOpacity={0.7}
                  style={[
                    styles.petDetail,
                    { paddingBottom: index === trainings.length - 1 ? Ratio(150) : Ratio(10) },
                  ]}
                  onPress={() => trainingDetails(item)}>
                  <PetCard
                    name={item.pet.name}
                    category={_.find(Constants.breeds, { value: item.pet.category })?.label}
                    gender={item.gender}
                    age={item.pet.age}
                    age_type={item.pet.age_type}
                    single_date={item.date}
                    start_date={returnDate(item , "start_date")}
                    end_date={returnDate(item, "end_date")}
                    training
                    username={item.trainer?.username}
                    user_type={
                      item.trainer?.user_type.charAt(0).toUpperCase() +
                      item.trainer?.user_type.slice(1)
                    }
                    training_type={item.service}
                    user_profile={item.trainer?.profile ? item.trainer?.profile : null}
                    profile={item.pet?.profile ? item.pet?.profile : null}
                    date
                    type={item.training_type}
                  />
                </TouchableOpacity>
              )}
              onEndReached={loadMore}
              onEndReachedThreshold={0.5}
              ListFooterComponent={loader()}
            />
          </View>
        ) : (
          <View style={styles.emptyContainer}>
            <Text color={Colors.secondaryLightText} size={14}>
              Trainings not found
            </Text>
          </View>
        )}
      </View>
      {!state.openFilter && (
        <LinearGradient
          colors={['rgba(248, 249, 251, 0)', 'rgba(248, 249, 251, 0.8)', 'rgba(248, 249, 251, 1)']}
          style={styles.overlay}
        />
      )}
      {state.openFilter && (
        <View style={styles.filterContainer}>
          <FilterModal onFilterClick={(value: string, head: string) => filterChange(value)} />
        </View>
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  trainingsContainer: {
    height: '100%',
  },
  trainingsWrapper: {
    width: '100%',
    height: '100%',
    padding: '0px 20px',
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '8%',
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    elevation: 10,
  },
  headTextContainer: {
    justifyContent: 'center',
    height: '100%',
    paddingLeft: Ratio(10),
  },
  navButtonContainer: {
    paddingLeft: Ratio(20),
    display:"flex",
    flexDirection: 'row',
  },
  navButtonContainerSearch: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: Ratio(20),
  },
  searchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  input: {
    width: '100%',
  },
  dogListWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: Ratio(10),
  },
  petDetail: {
    width: "49%",
    marginBottom: Ratio(10),
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: Ratio(150),
    width: '100%',
  },
  filterContainer: {
    position: 'absolute',
    bottom: 0,
    top: Ratio(100),
    left: 0,
    right: 0,
    width: '100%',
  },
  addButton:{
    borderRadius:"50%",
    backgroundColor: Colors.secondaryDarkColor,
    marginRight:"20px"
  },
});

export default Trainings;
