import { Form, Formik } from 'formik';
import React, { useRef } from 'react';
import Functions, { useSetState } from 'utils/functions.utils';
import {
  Assets,
  Models,
  Validation,
  Component,
  Input,
} from 'utils/imports.utils';
import '../login/login.screen.scss';
import _ from 'lodash';
import { Link } from 'react-router-dom';



function DeleteAccount(props: any) {
  const [state, setState] = useSetState({
    activity: false,
    secure: true,
    email: "",
    password: "",
    existingUserArray: [],
    deleteReq: false,
    reqFail: false,
    message: "",
  });
  const modalRef = useRef<any>(null);

  const deleteAccount = async () => {
    try {
      const req: any = await Models.user.deleteRequest({ email: state.email, password: state.password })
      setState({ deleteReq: true })
      console.log(req)
    } catch (error) {
      console.log(error)
      setState({ message: error, reqFail: true })
    }
    modalRef?.current?.close()
  }
  const openModal = async () => {
    try {
      await Validation.loginSchema.validate({
        password: state.password,
        email: state.email.trim(),
      });
      modalRef?.current?.showModal()
    } catch (error) {
      Functions.Failure("Please enter Email and Password")
    }

  }
  return (
    <div className="login_screen">
      <div className="login_container">
        {state.deleteReq || state.reqFail ? <>
          {state.reqFail ?
            <div className='delete_message'>
              <h2>{state?.message}</h2>
              <Component.Button text='Try Again' width={150} onClick={() => { setState({ deleteReq: false, reqFail: false }) }} />
            </div> :
            <div className='delete_message'>
              <h2>We've received your request to delete your account. It will be deleted in 48 hours</h2>
              {/* <Link to={"/home"}> Home</Link> */}
            </div>
          }

        </> :
          <div className='login_wrapper'>
            <div className="login_logo_wrapper">
              <img src={Assets.Logo} className="login_logo" alt="login_logo" />
            </div>
            <div className='form_label'>
              Delete Account
            </div>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={Validation.loginSchema}
              onSubmit={(values: any, { setSubmitting }) => {
                deleteAccount();
              }}>
              {({ isSubmitting }) => (
                <Form className="login_form">
                  <div className="form_control">
                    <Input
                      label="Email"
                      onChange={(value) => setState({ email: value })}
                      name="email"
                    />
                  </div>
                  <div className="form_control">
                    <Input
                      label="Password"
                      onChange={(value) => setState({ password: value })}
                      name="password"
                      type={state.secure ? 'password' : 'text'}
                      onIconClick={() => setState({ secure: !state.secure })}
                      icon={state.secure ? Assets.EyeHide : Assets.Eye}
                      iconPosition="end"
                    />
                  </div>
                  <div className='forget_password'>
                    {/* <div className='forget_password_button' onClick={() => { props.history.push('/ForgetPassword') }}>
                      Forgot Password
                    </div> */}
                  </div>
                  <div className="button_control">
                    <Component.Button
                      text="Delete"
                      type="submit"
                      activity={state.activity}
                      onClick={openModal}
                    // onClick={deleteAccount}
                    />
                  </div>
                  <dialog ref={modalRef} open={state.modalOpen} className='delete_modal'>
                    <div>
                      <p>Are you Sure you want to Delete your Account?</p>
                      <div>
                        <Component.Button
                          activity={state.activity}
                          type="submit"
                          onClick={deleteAccount}
                          text='Yes, Delete'
                          className='confirm_delete' />
                        <Component.Button
                          type='button'
                          text='No, Cancel'
                          onClick={() => modalRef?.current?.close()}
                        />
                      </div>
                    </div>
                  </dialog>
                </Form>
              )}
            </Formik>
          </div>
        }
      </div>
    </div>
  );
}

export default DeleteAccount;
